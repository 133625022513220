import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SingleDropdownListWithoutSearchBoot } from "./SingleDropdownListWithoutSearchBoot";
import { Form } from "react-bootstrap";
import moment from "moment";

interface DateRangeSelectorProps {
  onDateRangeChange: (startDate: Date | null, endDate: Date | null, selectedOption: string | null) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ onDateRangeChange }) => {
  const currentYear = new Date().getFullYear();

  const firstJan = new Date(currentYear, 0, 1);
  const [startDate, setStartDate] = useState<Date | null>(firstJan);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectedOption, setSelectedOption] = useState<string | null>("YTD");

  const options = [
    { id: "Last 7 Days", name: "Last 7 Days" },
    { id: "Last 30 Days", name: "Last 30 Days" },
    { id: "Last 60 Days", name: "Last 60 Days" },
    { id: "Last 90 Days", name: "Last 90 Days" },
    { id: "MTD", name: "MTD" },
    { id: "YTD", name: "YTD" },
    { id: "Date Range", name: "Date Range" },
  ];

  const handleSelectedItems = (selectedId: string) => {
    let newStartDate: Date | null = null;
    let newEndDate: Date | null = new Date();

    switch (selectedId) {
      case "Last 7 Days":
        newStartDate = moment().subtract(6, "days").toDate();
        break;
      case "Last 30 Days":
        newStartDate = moment().subtract(29, "days").toDate();
        break;
      case "Last 60 Days":
        newStartDate = moment().subtract(59, "days").toDate();
        break;
      case "Last 90 Days":
        newStartDate = moment().subtract(89, "days").toDate();
        break;
      case "MTD":
        const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        newStartDate = firstDayOfMonth;
        break;
      case "YTD":
        const firstJan = new Date(new Date().getFullYear(), 0, 1);
        newStartDate = firstJan;
        break;
      case "Date Range":
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setSelectedOption(selectedId);

    onDateRangeChange(newStartDate, newEndDate, selectedId);
  };

  const changeStartDate = (newStartDate: Date) => {
    let newEndDate = endDate;
    if (!newStartDate || !selectedOption) return;

    switch (selectedOption) {
      case "Last 7 Days":
        newEndDate = moment(newStartDate).add(6, "days").toDate();
        break;
      case "Last 30 Days":
        newEndDate = moment(newStartDate).add(29, "days").toDate();
        break;
      case "Last 60 Days":
        newEndDate = moment(newStartDate).add(59, "days").toDate();
        break;
      case "Last 90 Days":
        newEndDate = moment(newStartDate).add(89, "days").toDate();
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onDateRangeChange(newStartDate, newEndDate, selectedOption);
  };

  const changeEndDate = (newEndDate: Date) => {
    let newStartDate = startDate;
    if (!newEndDate || !selectedOption) return;

    switch (selectedOption) {
      case "Last 7 Days":
        newStartDate = moment(newEndDate).subtract(6, "days").toDate();
        break;
      case "Last 30 Days":
        newStartDate = moment(newEndDate).subtract(29, "days").toDate();
        break;
      case "Last 60 Days":
        newStartDate = moment(newEndDate).subtract(59, "days").toDate();
        break;
      case "Last 90 Days":
        newStartDate = moment(newEndDate).subtract(89, "days").toDate();
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    onDateRangeChange(newStartDate, newEndDate, selectedOption);
  };

  return (
    <div className="col-12 d-flex flex-column">
      <div className="row">
        <div className="col-4 p-1">
          <Form.Group controlId="customercode" style={{ marginTop: "2px" }}>
            <div>
              <SingleDropdownListWithoutSearchBoot
                itemList={options}
                handleSelectedItem={handleSelectedItems}
                defaultText={"Select"}
                defaultName={selectedOption}
                controlID="1"
                id={"ddlselected"}
              />
            </div>
          </Form.Group>
        </div>
        <div className="col-4 p-1">
          <Form.Group controlId="date" style={{ marginTop: "2px" }}>
            <div className="d-flex align-items-center">
              <DatePicker
                selected={startDate}
                onChange={changeStartDate}
                selectsStart
                className="form-control form-control-md text-center"
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                placeholderText="Select start date"
              />
            </div>
          </Form.Group>
        </div>
        <div className="col-4 p-1">
          <Form.Group controlId="date" style={{ marginTop: "2px" }}>
            <div className="d-flex align-items-center">
              <Form.Label className="font-semibold mb-0 me-2">To</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={changeEndDate}
                selectsEnd
                startDate={startDate}
                className="form-control form-control-md text-center"
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
                placeholderText="Select end date"
              />
            </div>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;
