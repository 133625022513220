import React from "react";
import ToolkitProvider, { CSVExport }  from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Customerorderslideout } from '../../Modules/Customerorderslideout';
import { ShippingSlideout } from '../../Modules/ShippingSlideout';
import { Quotationslideout } from '../../Modules/Quotationslideout';
import {Receivableslideout}  from '../../Modules/Accounts/Receivableslideout';
import { Vendororderslideout } from "../../Modules/Vendororderslideout";
import { JobCreations } from "../../Modules/JobCreations";
import { NCRSlideout } from "../../Modules/Job/NCRSlideout";
import { InvoiceEntryslideout } from "../../Modules/Purchasing/InvoiceEntryslideout";
import { Utils } from "../Utilis";
export class DynamicBootstrapTable extends React.Component<any, any> {
    constructor(props: any) {
    super(props);
     console.log("DynamicBootstrapTable",this.props.data);
    this.state = {
      data: this.props.data.length > 0 && this.props.data[0].transType === "vendorinvoice" ? this.props.data.filter(item => item.id != null) : this.props.data.length > 0 && this.props.data[0].transType === "invoice" ? this.props.data.filter(item => item.invoiceid != null): this.props.data
    };
  }
  handleRowClick = (row: any, rowIndex: number, event: any) => {
    console.log("Row clicked:", row, "at index:", rowIndex);
   debugger;

   if(row.transType=='customer')
   {
    this.setState({
      showSlideout: true, orderid: row.orderid,   shipmentId: 0, isSpinner: false,
    });
  }

  if(row.transType=='quotation')
    {
     this.setState({
      showSlideoutQuotation: true, orderid: row.quotationid,   shipmentId: 0, isSpinner: false,
     });
   }

   if(row.transType=='shipping')
    {
     this.setState({
      showShippingSlideout: true, shipmentId: row.id,   isSpinner: false,
     });
   }

   if(row.transType=='invoice')
   {
    this.setState({
      showReceivableslideout: true, 
      orderid: 0,
      invoiceId: row.invoiceid,    
      isSpinner: false,
     });

   }

   if(row.transType=='vendorinvoice')
    {
     this.setState({
       showVendorInvoiceslideout: true, 
       orderid: 0,
       invoiceId: row.id,    
       isSpinner: false,
      });
 
    }

   if(row.transType=='vendororder')
    {
     this.setState({
       showVendorslideout: true, 
       isSpinner: false,
       vendororderid: row.orderid,
       vendorordertype: row['Order Type'],
       orderid: row.orderid
      });
    }

    if(row.transType=='job')
      {
       this.setState({
         showJobslideout: true, 
         isSpinner: false,
         jobid: row.jobid,
         orderid:row.orderid,
         productid:row.productid
        });
      }

      if(row.transType=='ncr')
        {
         this.setState({
          showNCRslideout: true, 
           isSpinner: false,
           id: row.id,
           jobid: row.jobid,
           jobno: row['Job No.']
          });
        }
    
    // Add your custom logic here (e.g., navigate, show modal, etc.)
  };
  closeSlideOut = () => {
    this.setState({ showSlideout: false,showSlideoutQuotation:false, isSpinner: false, });

  };

  closeVendorSlideOut = () => {
    this.setState({ showVendorslideout:false, isSpinner: false, footershow:false });
  };

  closeShippingSlideOut = () => {
    this.setState({ showShippingSlideout: false,showReceivableslideout:false, isSpinner: false, footershow:false });

  };


  render() {
    const {
      showSlideout,
      showShippingSlideout,
      showSlideoutQuotation,
      showReceivableslideout,
      showVendorslideout,
      showJobslideout,
      showNCRslideout,
      showVendorInvoiceslideout
    } = this.state;

    const { ExportCSVButton } = CSVExport;
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.props.data.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };
    
    const { data = [] } = this.props;

    // Handle case where data is empty
    if (!data.length) {
      return <div>No data available</div>;
    }

    // const columns = Object.keys(data[0]).map((key) => ({
    //   dataField: key,
    //   text: key.replace(/([A-Z])/g, " $1").toUpperCase(),
    //   sort: true,
    //   headerStyle: { textAlign: "center" },
    //   formatter: (cell: any, row: any) => (
    //     <EllipsisWithTooltip placement="bottom">
    //       {row[key] || "N/A"}
    //     </EllipsisWithTooltip>
    //   ),
    // }));

    const columns = Object.keys(this.props.data[0]).map((key) => {
      const isCurrencyField = ['total amount', 'discount', 'total', 'unit price','order amount','order total','invoice total','sale tax amount'].includes(key.toLowerCase());
    
      const isHideField = ['transtype', 'orderid','quotationid', 'invoiceid','id'].includes(key.toLowerCase());
    

      return {
        dataField: key,
        text: key.replace(/([A-Z])/g, ' $1').toUpperCase(),
        sort: true,
        headerStyle: { textAlign: 'center' , position: 'relative', zIndex:'-1'},
        hidden: isHideField ? true : false,
        classes: isCurrencyField ? 'text-end pe-4 divgreen' :  isHideField ? 'd-none' :  'text-start',
        formatter: (cell: any, row: any) => (
          <EllipsisWithTooltip placement="bottom">
            {isCurrencyField ? Utils.currencyFormat(row[key]) : row[key] || ""}
          </EllipsisWithTooltip>
        ),
      };
    });

    const rowEvents = {
      onClick: (e: any, row: any, rowIndex: number) => this.handleRowClick(row, rowIndex, e),
    };


    return (
<>
{(showSlideout &&
  <Customerorderslideout
    closeSlideOut={this.closeSlideOut}
    orderid={this.state.orderid}
  
  >


  </Customerorderslideout>
)}
   {(showSlideoutQuotation &&
                <Quotationslideout
                  closeSlideOut={this.closeSlideOut}
                  orderid={this.state.orderid}
                
                >
                </Quotationslideout>
              )}


{(showShippingSlideout &&
  <ShippingSlideout
    closeSlideOut={this.closeShippingSlideOut}
    orderid={this.state.orderid}
    jobids={this.state.jobIds}
    shipmentId={this.state.shipmentId}
  >


  </ShippingSlideout>
)}

{(showReceivableslideout &&
                <Receivableslideout
                  closeSlideOut={this.closeShippingSlideOut}
                  orderid={this.state.orderid}
                  orderIds={this.state.orderIds}
                  products={this.state.products}
                  invoiceId={this.state.invoiceId}
                >


                </Receivableslideout>
              )}

          {(showVendorslideout && 
        <Vendororderslideout
        closeSlideOut={this.closeVendorSlideOut}
        orderid={this.state.orderid}
        vendororderid={this.state.vendororderid}
        vendorordertype={this.state.vendorordertype}
        id={this.state.vendororderid}
        ></Vendororderslideout>
          )}

        {showJobslideout && 
        <JobCreations
          closeSlideOut={()=>{this.setState({showJobslideout:false});}}
          jobid={this.state.jobid}
          orderid={this.state.orderid}
          productid={this.state.productid}
          id={this.state.orderid}
        >
        </JobCreations>
        }
        {showNCRslideout && 
        <NCRSlideout
          closeSlideOut={()=>{this.setState({showNCRslideout:false});}}
          jobid={this.state.jobid}
          id={this.state.id}
          jobno={this.state.jobno}
        
        ></NCRSlideout>
        }
        {showVendorInvoiceslideout && 
        <InvoiceEntryslideout
        closeSlideOut={()=>{this.setState({showVendorInvoiceslideout:false});}}
        orderIds={[]}
        invoiceId={this.state.invoiceId}
        itemIds={[]}
        />
        }
        <ToolkitProvider
          keyField="id"
          data={this.state.data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <section className="pb-3">
                   
              <div className="page-heading  d-flex  ">
          <div className="action-group d-flex flex-row ml-auto">
              
          <ExportCSVButton {...props.csvProps}>
            Export to CSV / Excel
          </ExportCSVButton>
            </div>
</div>
               <section 
               className={`pb-3 ${!showSlideout && !showShippingSlideout && !showSlideoutQuotation  && !showNCRslideout && !showVendorslideout && !showReceivableslideout &&  !showJobslideout && !showVendorInvoiceslideout ? '' : 'search'}`}
               >
                <div className="table-responsive divbottom">
                  <div className="ml-auto transactions-list">
               
          {/* <button className="btn btn-primary m-2" onClick={exportPDF}>
            Export to PDF
          </button>
          <button className="btn btn-warning m-2" onClick={exportExcel}>
            Export to Excel
          </button> */}
                    <BootstrapTable {...props.baseProps} 
                    pagination={paginationFactory(options)}
                    exportCSV
                    rowEvents={rowEvents}
                    />
                  </div>
                </div>
                </section>
              </section>
            </div>
          )}

        </ToolkitProvider>
        </>

      );
  }


 
}